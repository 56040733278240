import React, { Fragment, ReactNode, ComponentType, LegacyRef, useState, useCallback } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, DropdownProps, DropdownMenuProps } from "reactstrap";
import { IconToTheSide } from "../buttons/FormControlButton";
import ReactDOM from "react-dom";
import _ from "lodash";
import type { SearchToSelectRendererState, SelectorRendererState, VarySelectionType } from "./types";

export interface DropdownSelectorRendererConfigProps<T> {
  /** Test ID for the component */
  dataTestId?: string;

  // Behavior Configuration

  // Rendering Functions or configuration
  /** Function to render a single option */
  renderOption?: OptionRenderer<T>;
  /** Function to render a selected option */
  renderSelectedOption?: OptionRenderer<T>;
  /** Function to render multiple selections summary */
  renderMultiSelected?: (items: T[]) => ReactNode;
  renderOptions?: ComponentType<DropdownPaneOptionsProps<T>>;
  /** Placeholder text when no selection */
  placeholder?: ReactNode;
  /** Component to show if there's nothing to select */
  noOptionsText?: ReactNode;
  /** Component to show if there's nothing more to select */
  noMoreOptionsText?: ReactNode;

  // Visual Customization
  /** Additional CSS classes */
  className?: string; // TODO - not yet implemented?
  // TODO - menu button props - something to do with social publishing messy fiasco
  /** Additional CSS styles to attach to the containerr */
  style?: React.CSSProperties;
  /** Whether to use fixed positioning for the dropdown */
  positionFixed?: boolean;
  /** Makes dropdown align-right */
  right?: boolean;
  /** Maximum height of the dropdown menu */
  maxHeight?: number;
  /** Minimum height of the dropdown menu */
  minHeight?: number;
}

export type OptionRenderer<T> = (item: T) => ReactNode;

/////////////////////////////
//        Container        //
/////////////////////////////

export type DropdownSelectorContainerRendererProps = {
  isOpen: boolean;
  toggle: () => void;
  dataTestId?: string;
} & Partial<DropdownProps>;

export function DropdownSelectorContainerRenderer({
  isOpen,
  toggle,
  dataTestId,
  ...rest
}: DropdownSelectorContainerRendererProps) {
  const noArgsToggle = useCallback(() => {
    toggle();
  }, [toggle]);
  return (
    <Dropdown data-testid={dataTestId} isOpen={isOpen} toggle={noArgsToggle} {...(rest as Partial<DropdownProps>)} />
  );
}

/////////////////////////////
//        Toggle           //
/////////////////////////////

export type DropdownButtonSelectorToggleProps<T> = {
  disabled: boolean;
  placeholder: ReactNode;
  selections: readonly T[];
  renderSelectedOption: OptionRenderer<T>;
  renderMultiSelected: (items: T[]) => ReactNode;
  removeSelection: (item: T) => void;
  overrideButtonContent?: ReactNode;
  dropdownRef?: LegacyRef<DropdownToggle>;
  toggle: () => void;
} & VarySelectionType<T>;

export function DropdownButtonSelectorToggle<T>({
  disabled,
  required,
  placeholder,
  multi,
  selections,
  renderSelectedOption,
  renderMultiSelected,
  removeSelection,
  overrideButtonContent,
  dropdownRef,
  toggle,
}: DropdownButtonSelectorToggleProps<T>): JSX.Element {
  let toggleButton: ReactNode;
  if (overrideButtonContent) {
    toggleButton = overrideButtonContent;
  } else if (selections.length === 1 && (multi || !required)) {
    toggleButton = (
      <IconToTheSide
        side="right"
        icon={
          <span
            className={`far fa-times`}
            onClick={(e) => {
              if (!disabled) {
                e.stopPropagation();
                removeSelection(selections[0]);
              }
            }}
          />
        }
      >
        {renderSelectedOption(selections[0])}
      </IconToTheSide>
    );
  } else {
    toggleButton = (
      <IconToTheSide side="right" icon={<i className="far fa-chevron-down"></i>}>
        {selections.length === 0
          ? placeholder
          : multi
          ? renderMultiSelected?.(selections as T[])
          : renderSelectedOption(selections[0])}
      </IconToTheSide>
    );
  }

  return (
    <DropdownToggle
      ref={dropdownRef}
      disabled={disabled}
      tag="span"
      tabIndex={disabled ? -1 : 0}
      onKeyDown={(e) => {
        if (!disabled && (e.key === "Enter" || e.key === " " || e.key === "ArrowDown")) {
          e.preventDefault();
          toggle();
        }
      }}
      className={`dropdown-toggle dropdown-toggle-no-icon form-control ${disabled ? "form-control-disabled" : ""}`}
    >
      {toggleButton}
    </DropdownToggle>
  );
}

//////////////////////////////////////
//      Optional  Header/Footer     //
//////////////////////////////////////

export type DropdownSelectorHeaderProps<T> = SelectorRendererState<T> & VarySelectionType<T>;
export type DropdownSelectorFooterProps<T> = SelectorRendererState<T> & VarySelectionType<T>;

/////////////////////////////
//        Menu             //
/////////////////////////////

export type DropdownSelectorMenuContainerProps<T> = {
  maxHeight: number;
  minHeight: number;
  toggleRef?: any;
  direction?: string;
  right?: boolean;
  positionFixed: boolean;
  style?: React.CSSProperties;
  isOpen: boolean;
  className?: string;
  children: ReactNode;
};

interface DropdownMenuState {
  height: number;
  menuRef: HTMLElement | null;
}
export class DropdownSelectorMenuContainer<T> extends React.Component<
  DropdownSelectorMenuContainerProps<T>,
  DropdownMenuState
> {
  private _isMounted = false;
  private updateMenuHeightFromEvent = _.throttle(() => {
    this.updateMenuHeight(this.props, this.state.menuRef);
  }, 17); // 60fps

  constructor(props: DropdownSelectorMenuContainerProps<T>) {
    super(props);
    this.state = { height: props.minHeight, menuRef: null };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updateMenuHeightFromEvent, true);
    window.removeEventListener("scroll", this.updateMenuHeightFromEvent, true);
  }

  componentDidUpdate(prevProps: DropdownSelectorMenuContainerProps<T>) {
    if ((this.props.isOpen && !prevProps.isOpen) || this.props.toggleRef !== prevProps.toggleRef) {
      // Initial height update
      this.updateMenuHeight(this.props, this.state.menuRef);

      // Set up event listeners and do a delayed update
      if (this.props.isOpen && !prevProps.isOpen) {
        window.addEventListener("resize", this.updateMenuHeightFromEvent, true);
        window.addEventListener("scroll", this.updateMenuHeightFromEvent, true);
        setTimeout(() => this.updateMenuHeight(), 50);
      }
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      // Clean up event listeners when closing
      window.removeEventListener("resize", this.updateMenuHeightFromEvent);
      window.removeEventListener("scroll", this.updateMenuHeightFromEvent);
      this.setState({ height: this.props.minHeight });
    }
  }

  setMenuRef = (elem: HTMLElement | null) => {
    if (this.state.menuRef !== elem) {
      this.setState({ menuRef: elem });
      this.updateMenuHeight(this.props, elem);
    }
  };

  updateMenuHeight = (props = this.props, menuRef = this.state.menuRef) => {
    const dir = props.direction || "down";
    if (props.toggleRef && menuRef && (dir === "up" || dir === "down") && props.isOpen && this._isMounted) {
      const toggleNode = props.toggleRef instanceof Element ? props.toggleRef : ReactDOM.findDOMNode(props.toggleRef);
      const menuNode = menuRef instanceof Element ? menuRef : ReactDOM.findDOMNode(menuRef);

      if (toggleNode instanceof Element && menuNode instanceof Element) {
        const toggleRect = toggleNode.getBoundingClientRect();
        const menuRect = menuNode.getBoundingClientRect();
        const min = props.minHeight;
        const max = props.maxHeight;
        const aboveToggle = toggleRect.top;
        const windowHeight = document.documentElement.clientHeight;
        const belowToggle = windowHeight - (Math.max(toggleRect.top, 0) + toggleRect.height);
        const buffer = 40;
        let available;
        if (toggleRect.top > menuRect.top) {
          available = aboveToggle;
        } else {
          available = belowToggle;
        }
        const height = Math.max(min, Math.min(max, available - buffer));
        this.setState({ height });
      }
    }
  };

  render() {
    const { style, direction, positionFixed, right } = this.props;

    const dropdownMenuProps: DropdownMenuProps & { tabIndex: number } = {
      tabIndex: 0,
      className: `w-100 ${this.props.className || ""}`,
      style: {
        maxHeight: `${this.state.height}px`,
        overflowY: "auto" as const,
        ...style,
      },
      direction: direction || "down",
      "data-testid": "dropdown-selections",
      right: right,
      positionFixed: positionFixed,
      modifiers: {
        preventOverflow: {
          enabled: true,
          escapeWithReference: true,
          boundariesElement: "viewport",
        },
        flip: {
          enabled: true,
        },
      },
      children: this.props.children,
    };

    return (
      <div ref={this.setMenuRef}>
        <DropdownMenu {...dropdownMenuProps} />
      </div>
    );
  }
}

/////////////////////////////
//        DropdownPane     //
/////////////////////////////

export type DropdownPaneOptionsProps<T> = {
  renderOption: OptionRenderer<T>;
} & Pick<DropdownSelectorRendererConfigProps<T>, "renderSelectedOption" | "renderMultiSelected"> &
  Pick<SelectorRendererState<T>, "activeKey" | "addSelection" | "selections" | "options" | "getKey" | "multi">;
export class DropdownPaneOptions<T> extends React.Component<DropdownPaneOptionsProps<T>> {
  private refsByKey: { [key: string]: any } = {};
  setRef = (key: string, ref: any) => {
    this.refsByKey[key] = ref;
  };

  componentDidUpdate(prevProps: DropdownPaneOptionsProps<T>) {
    if (this.props.activeKey && this.props.activeKey !== prevProps.activeKey) {
      const ref = this.refsByKey[this.props.activeKey];
      if (ref) {
        const node = ReactDOM.findDOMNode(ref);
        if (node instanceof Element) {
          node.scrollIntoView?.({
            block: "nearest",
            inline: "nearest",
          });
        }
      }
    }
  }

  render() {
    const { multi, selections, getKey, options, renderOption, addSelection, activeKey } = this.props;

    const selectedKey = !multi && selections[0] && getKey(selections[0]);

    return (
      <Fragment>
        {options.map((x) => {
          const key = getKey(x);
          return (
            <DropdownItem
              key={key}
              ref={(r) => this.setRef(key, r)}
              toggle={!multi}
              active={!!activeKey && key === activeKey}
              disabled={!!selectedKey && key === selectedKey}
              onClick={() => {
                addSelection(x);
              }}
            >
              {renderOption(x)}
            </DropdownItem>
          );
        })}
      </Fragment>
    );
  }
}

/////////////////////////////
//        Selections       //
/////////////////////////////

export type DropdownPaneSelectionsProps<T> = {
  renderOption: OptionRenderer<T>;
} & Pick<DropdownSelectorRendererConfigProps<T>, "renderSelectedOption" | "renderMultiSelected"> &
  Pick<SelectorRendererState<T>, "selections" | "getKey" | "removeSelection" | "activeKey">;

export class DropdownPaneSelections<T> extends React.Component<DropdownPaneSelectionsProps<T>> {
  render() {
    const { selections, getKey, renderOption, removeSelection, activeKey } = this.props;
    return (
      <Fragment>
        {selections.map((x) => {
          const key = getKey(x);
          if (key === undefined || (key === null && process.env.NODE_ENV === "development")) {
            console.warn("get undefined for key for option", key, "option", x);
          }
          return (
            <DropdownItem toggle={false} key={key} active={activeKey === key} onClick={() => removeSelection(x)}>
              <span className="selector-selection">
                <IconToTheSide side="right" key={key} icon={<span className="far fa-times" />}>
                  {renderOption(x)}
                </IconToTheSide>
              </span>
            </DropdownItem>
          );
        })}
      </Fragment>
    );
  }
}

///////////////////////////////
//    The Actual Renderer    //
///////////////////////////////

export function DropdownSelectorRenderer<T>({
  // Base selector props
  isOpen,
  toggle,
  multi,
  required,
  selectByKey,
  selected,
  onSelected,
  getKey,
  selections,
  options,
  renderOption = (opt: any) => (opt && opt.value ? opt.value : opt),
  renderSelectedOption,
  noOptionsText = (
    <DropdownItem disabled>
      <span className="text-muted">No Results</span>
    </DropdownItem>
  ),
  addSelection,
  removeSelection,
  activeKey,
  disabled,
  placeholder,
  renderMultiSelected = (items: any[]) => items.length + " Selections",
  dataTestId,
  renderOptions: Options = DropdownPaneOptions,
  // Additional props
  right,
  style,
  positionFixed = false,
  maxHeight = 450,
  minHeight = 150,
}: DropdownSelectorRendererConfigProps<T> & SelectorRendererState<T>) {
  const [toggleRef, setToggleRef] = useState<any>();

  const varyProps: VarySelectionType<T> = {
    multi: multi,
    required: required,
    selectByKey: selectByKey,
    selected: selected,
    onSelected: onSelected,
  } as VarySelectionType<T>;

  const renderContent = () => (
    <>
      {multi && (
        <DropdownPaneSelections
          renderOption={renderOption}
          renderSelectedOption={renderSelectedOption}
          renderMultiSelected={renderMultiSelected}
          selections={selections}
          getKey={getKey}
          removeSelection={removeSelection}
          activeKey={activeKey}
        />
      )}
      {multi && selections.length > 0 && <DropdownItem divider />}
      <Options
        options={options}
        renderOption={renderOption}
        selections={selections}
        addSelection={addSelection}
        getKey={getKey}
        activeKey={activeKey}
        multi={multi}
      />
      {!options.length ? noOptionsText : null}
    </>
  );

  return (
    <DropdownSelectorContainerRenderer isOpen={isOpen} toggle={toggle} dataTestId={dataTestId}>
      <DropdownButtonSelectorToggle
        dropdownRef={setToggleRef}
        disabled={disabled}
        {...varyProps}
        placeholder={placeholder}
        selections={selections}
        renderSelectedOption={renderSelectedOption || renderOption}
        renderMultiSelected={renderMultiSelected}
        removeSelection={removeSelection}
        toggle={toggle}
      />
      <DropdownSelectorMenuContainer
        right={right}
        positionFixed={positionFixed}
        toggleRef={toggleRef}
        {...varyProps}
        style={style}
        isOpen={isOpen}
        maxHeight={maxHeight}
        minHeight={minHeight}
      >
        {renderContent()}
      </DropdownSelectorMenuContainer>
    </DropdownSelectorContainerRenderer>
  );
}
