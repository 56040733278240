import React from "react";
import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";
import "./Button.css";
import { Placement } from "@popperjs/core";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  outline?: boolean;
  icon?: string;
  tooltipId?: string;
  tooltipMessage?: string;
  tooltipPlacement?: Placement;
}

/**
 * This component's primary purpose ist to assist with icon spacing within a button that has an icon.
 * For simple buttons, you should just use plain html and btn classes.
 *
 * ## Style Guide
 * - Only the primary button is a solid fill. All other buttons are outlined.
 * - Use the `secondary` color variant for all buttons.
 * - Most of the time buttons should be right-aligned.
 * - Primary buttons should be the outermost button (From left to right).
 * - Controls should space themselves to the left, ie succeeding buttons should space themselves.
 * - @augments {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>}
 */

const Button = React.forwardRef(function Button(
  {
    outline = false,
    icon = "",
    children = [],
    className = "",
    color = "secondary",
    disabled = false,
    tooltipId = "",
    tooltipMessage = "",
    tooltipPlacement = "left",
    ...rest
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const elements = React.Children.toArray(children);
  return (
    <>
      {tooltipId !== "" ? (
        <>
          <button
            id={tooltipId}
            className={`btn btn-${outline ? "outline-" : ""}${color} ${className || ""}`}
            disabled={disabled}
            ref={ref}
            {...rest}
          >
            {!!icon && <i className={icon}></i>}
            {!!elements.length && <span className={icon && children ? "content-spacer-left" : ""}>{children}</span>}
          </button>
          <UncontrolledTooltip className={"clone-button-tooltip"} placement={tooltipPlacement} target={tooltipId}>
            {tooltipMessage}
          </UncontrolledTooltip>
        </>
      ) : (
        <button
          className={`btn btn-${outline ? "outline-" : ""}${color} ${className || ""}`}
          disabled={disabled}
          ref={ref}
          {...rest}
        >
          {!!icon && <i className={icon}></i>}
          {!!elements.length && <span className={icon && children ? "content-spacer-left" : ""}>{children}</span>}
        </button>
      )}
    </>
  );
});

Button.propTypes = {
  outline: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
