import * as mobx from "mobx";
import { observer } from "mobx-react";
import React, { Fragment } from "react";
import { DropdownItem } from "reactstrap";
import { SearchToSelect, Spinner } from "../../components/index";
import {
  BaseSearchToSelectProps,
  VarySelectionType,
  SearchParams,
  SearchResult,
} from "../../components/selector/types";
import { LOCATION_TYPE } from "./entityTypes";
import { formatPostalAddress } from "./locationAddress/util";
import { initialPagination, loadOptions } from "./locationGroupPaginatedSearch";

interface LocationGroup {
  entityIdString: string;
  entityId: {
    entityType: string;
  };
  name: string;
  totalLocations: number;
  address?: {
    [key: string]: any;
  };
}

type LocationGroupSelectorProps = Omit<BaseSearchToSelectProps<LocationGroup>, "getKey" | "getSearchResults"> &
  VarySelectionType<LocationGroup> & {
    includeAccounts?: boolean;
    placeholder?: string;
  };

export const LocationGroupSelector = observer(
  ({ selected, placeholder, includeAccounts, ...props }: LocationGroupSelectorProps) => {
    const selectedItems = mobx.toJS(selected) satisfies LocationGroupSelectorProps["selected"];
    return (
      <SearchToSelect<LocationGroup>
        {...props}
        getKey={(x) => x.entityIdString}
        placeholder={placeholder || "All Location Groups"}
        additional={initialPagination({ includeAccounts })}
        getSearchResults={getSearchResults}
        renderMultiSelected={(x) => `${x.length} Location Groups`}
        renderOption={(x) => nameWithCountIfNotLocation(x)}
        renderSelectedOption={(x) => (
          <span>
            <strong>{x.entityId.entityType}:</strong> {nameWithCountIfNotLocation(x)}
          </span>
        )}
        delay={100}
        selected={
          selectedItems as any /** Otherwise this would need to do a bunch of ternary gymnastics. The type has already been validated via the incoming props. */
        }
        renderOptions={LocationGroupOptions}
      />
    );
  }
);

function getSearchResults(params: SearchParams): Promise<SearchResult<LocationGroup>> {
  return loadOptions(params.query, params.additional).then((result) => ({
    ...result,
    items: result.options.filter((x) => x.entityId.entityType !== "User"),
  }));
}

interface LocationGroupOptionsProps {
  loading: boolean;
  activeIndex: number;
  options: readonly LocationGroup[];
  multi?: boolean;
  selections: readonly LocationGroup[];
  renderOption: (item: LocationGroup) => React.ReactNode;
  addSelection: (item: LocationGroup) => void;
  getKey: (item: LocationGroup) => string;
}

/**
 * Adds a heading between location groups
 */
export function LocationGroupOptions({
  loading,
  activeIndex,
  options,
  multi,
  selections,
  renderOption,
  addSelection,
  getKey,
}: LocationGroupOptionsProps) {
  const selectedKey = !multi && selections[0] && getKey(selections[0]);
  let currentType: string | undefined = undefined;

  return loading ? (
    <DropdownItem disabled toggle={false}>
      <Spinner timeout={200} center />
    </DropdownItem>
  ) : (
    <Fragment>
      {options.map((x, i) => {
        let before: React.ReactNode;
        if (x.entityId.entityType !== currentType) {
          currentType = x.entityId.entityType;
          before = (
            <Fragment key={`header-${currentType}`}>
              {i === 0 ? null : <DropdownItem divider />}
              <DropdownItem toggle={false} disabled={true}>
                <strong>{x.entityId.entityType}s</strong>
              </DropdownItem>
              <DropdownItem divider />
            </Fragment>
          );
        }
        const key = getKey(x);
        return (
          <Fragment key={key}>
            {before}
            <DropdownItem
              toggle={!multi}
              active={i === activeIndex}
              disabled={!!selectedKey && key === selectedKey}
              onClick={() => addSelection(x)}
            >
              {renderOption(x)}
            </DropdownItem>
          </Fragment>
        );
      })}
    </Fragment>
  );
}

/**
 * @param {LocationGroup} entity
 * @returns {string}
 */
export function nameWithCountIfNotLocation(entity: LocationGroup): string {
  return (
    entity.name +
    (entity.entityId.entityType !== LOCATION_TYPE
      ? ` (${entity.totalLocations})`
      : `, ${formatPostalAddress(entity.address)}`)
  );
}

export default LocationGroupSelector;
