import * as mobx from "mobx";
import { makeObservable } from "mobx";
import { action, observable } from "mobx";
import { stallUnresolvedPromises } from "../../util/async";
import BrandConfigStore from "../config/BrandConfigStore";
import { upsertUserPreferences, userPreferences } from "../users/usersApi";
import "./debugger";
import LoginStore from "./LoginStore";
import "./logoutOnUnauthorized";
import * as SessionApi from "./SessionApi";
import { Account, ExtendedUser } from "./Authentication";
import { BrandConfig } from "../config/BrandConfig";
import { UserPreferences } from "./UserPreferences";

/**
 * I contain Lightweight things that should always be accessible when a user is logged in
 * - the user
 * - the users account
 * - the brand config
 */

export class SessionStore {
  sessionLoaded = false;

  sessionToken: string | null = null;

  user: ExtendedUser | null = null;

  userPreferences: UserPreferences | null = null;

  /** @deprecated - use the BrandConfigStore */
  get brandConfig(): BrandConfig | null {
    return BrandConfigStore.brandConfig;
  }

  account: Account | null = null;

  bootStarted = false;

  constructor() {
    makeObservable(this, {
      sessionLoaded: observable,
      sessionToken: observable,
      user: observable,
      userPreferences: observable,
      account: observable,
      _loadSession: action,
      _setLoaded: action,
      assignUserPreferencesWithDefaults: action,
      reloadPreferences: action,
      unload: action,
      patchUserPreferences: action,
    });

    mobx.reaction(
      () => !!LoginStore.token,
      (token) => {
        if (token) {
          this._loadSession(LoginStore.token);
        } else {
          this.unload();
        }
      },
      { fireImmediately: true }
    );
  }

  _loadSession(token: string | null) {
    if (!token) {
      throw new Error("token must be specified");
    }
    this._getUsersAndAccount().then(([user, userPreferences, account]) =>
      this._setLoaded({ user, userPreferences, account })
    );
  }

  _setLoaded({
    account,
    userPreferences,
    user,
  }: {
    account: Account;
    userPreferences: UserPreferences;
    user: ExtendedUser;
  }): void {
    this.account = account;
    this.user = user;
    this.assignUserPreferencesWithDefaults(userPreferences);
    this.sessionLoaded = true;
  }

  _getUsersAndAccount = stallUnresolvedPromises(() => {
    return Promise.all([
      SessionApi.getCurrentUser(),
      userPreferences(),
      SessionApi.getCurrentUsersAccount(),
      mobx.when(() => !!BrandConfigStore.brandConfig),
    ]);
  });

  assignUserPreferencesWithDefaults = (userPreferences: UserPreferences) => {
    this.userPreferences = {
      stickyUrl: false,
      // set some default values since mobx doesn't like detecting changes
      // when new fields are added
      ...userPreferences,
    };
  };

  reloadPreferences(): void {
    this.userPreferences = null;
    userPreferences().then((pref) => this.assignUserPreferencesWithDefaults(pref));
  }

  unload() {
    if (this.sessionLoaded) {
      const redirectUrl = this.brandConfig && this.brandConfig.logoutRedirectUrl;
      this.sessionLoaded = false;
      this.account = null;
      this.user = null;
      this.userPreferences = null;
      if (redirectUrl) {
        (window as any).location = redirectUrl;
      }
    }
  }

  /**
   * Convenience method for accessing the current accounts reports service.
   * Note that the reportsService is set from the root account. The server response
   * fills this in for the currentAccount endpoint
   */
  get reportsService() {
    return this.account && this.account.services.reportsService;
  }

  onceUnloaded = (): Promise<void> & { cancel: () => void } => {
    return mobx.when(() => !this.sessionLoaded);
  };

  onceLoaded = () => {
    return mobx.when(() => this.sessionLoaded);
  };

  impersonateUser(username: string) {
    const target = window.open("/#/wait", "_blank")!;
    return SessionApi.generateSSOToken(username).then((resp) => {
      target.location = `/#/login?iFrame=false&ssoToken=${resp.ssoToken}`;
    });
  }

  hasUserPermission = (permission: string) => {
    return this.user && this.user.permissions.includes(permission);
  };

  /** patches user preferences and flushes them to the server
   * @param {Partial.<UserPreferences>} updates
   * @returns {Promise.<void>}
   */
  patchUserPreferences({ userId, userPreferences }: { userId: string; userPreferences: UserPreferences }) {
    if (userId === this.user?.userId) {
      Object.assign(this.userPreferences!, userPreferences);
    }
    return upsertUserPreferences({ userId, userPreferences });
  }
}

const defaultSessionStore = new SessionStore();

export default defaultSessionStore;
