import * as React from "react";
import * as PropTypes from "prop-types";
import { BufferedTextSearch } from "../../../components";
import { Dropdown, DropdownToggle, DropdownItem } from "reactstrap";
import { DropdownSelectorMenuContainer } from "../../../components/selector/DropdownSelectorRenderer";
import * as socialApi from "../socialApi";

export class SavedKeywordSearch extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onValueChanged: PropTypes.func.isRequired,
    inputClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
  };

  state = {
    isOpen: false,
    toggleRef: undefined,
    savedKeywords: [],
  };

  componentDidMount() {
    this.loadFilters();
  }

  loadFilters = () => {
    socialApi.getSearchFilters().then(({ socialSearchFilters }) => {
      this.setState({ savedKeywords: socialSearchFilters.sort((a, b) => a.query.localeCompare(b.query)) });
    });
  };

  toggle = () => this.setState((s) => ({ isOpen: !s.isOpen }));

  setToggleRef = (ref) => this.setState({ toggleRef: ref });

  onValueSubmitted = (value) => {
    this.setState({ isOpen: false });
    this.props.onValueChanged(value);
  };

  onSaveKeyword = () => {
    if (!this.state.savedKeywords.includes(this.props.value) && this.props.value && this.props.value.trim()) {
      this.setState({
        isOpen: false,
      });
      socialApi.saveSearchFilters({ query: this.props.value }).then(this.loadFilters);
    }
  };

  onDeleteKeyword = (savedKeyword) => {
    this.setState({ savedKeywords: this.state.savedKeywords.filter((x) => x !== savedKeyword) });
    socialApi.deleteSearchFilters(savedKeyword._id.$oid).then(this.loadFilters);
  };

  render() {
    const { value } = this.props;
    const { savedKeywords, toggleRef, isOpen } = this.state;
    const canSaveKeyword =
      !!value && !savedKeywords.find((x) => x.query.toLowerCase().trim() === value.toLowerCase().trim());
    const openAndSomethingToShow = isOpen && (!!savedKeywords.length || canSaveKeyword);
    return (
      <div className="d-flex">
        <div className="flex-fill">
          <Dropdown isOpen={openAndSomethingToShow} toggle={this.toggle}>
            <DropdownToggle tag="span" ref={this.setToggleRef} className="d-block">
              <div className="input-group">
                <BufferedTextSearch
                  value={value}
                  onValueSubmitted={this.onValueSubmitted}
                  placeholder={'e.g. #hashtag, @mention, or "a phrase"'}
                />
                <div className="input-group-append">
                  <i className="input-group-text far fa-search d-flex"></i>
                </div>
              </div>
            </DropdownToggle>
            <DropdownSelectorMenuContainer
              isOpen={openAndSomethingToShow}
              tabIndex="0"
              toggleRef={toggleRef}
              style={{
                width: "100%",
                overflowY: "scroll",
              }}
            >
              <div className="m-2">
                {canSaveKeyword && (
                  <button
                    className={`btn btn-secondary btn-block ${this.props.buttonClassName}`}
                    onClick={this.onSaveKeyword}
                  >
                    Save Search Term '{value}'
                  </button>
                )}
              </div>
              {savedKeywords.map((item) => {
                return (
                  <DropdownItem key={item.query}>
                    <div className="d-flex">
                      <a className="flex-grow-1" tabIndex="0" onClick={() => this.onValueSubmitted(item.query)}>
                        {item.query}
                      </a>
                      <a tabIndex="0" className="text-decoration-none" onClick={(e) => this.onDeleteKeyword(item)}>
                        <span className="far fa-times" />
                      </a>
                    </div>
                  </DropdownItem>
                );
              })}
            </DropdownSelectorMenuContainer>
          </Dropdown>
        </div>
      </div>
    );
  }
}
