/**
 * The base style comes from:
 *    https://github.com/highcharts/highcharts/tree/master/js/themes
 *
 * However, this has been heavily modified to use our theme variables. Cheers!
 */

import * as Highcharts from "highcharts";
import * as SessionStorage from "../../services/storage/sessionStorage";
import colors from "./colors";

const highchartsDefaultThemeKey = "highchartsDefaultTheme";

const useHighchartsTheme = () => {
  const getDefaultTheme = (): Highcharts.Options => {
    return SessionStorage.getObject(highchartsDefaultThemeKey);
  };

  saveDefaultTheme();
  setHighchartsTheme();

  return { getDefaultTheme };
};

export const saveDefaultTheme = () => {
  if (!SessionStorage.getObject(highchartsDefaultThemeKey)) {
    const defaulTheme = { ...Highcharts.getOptions() };

    SessionStorage.setObject(highchartsDefaultThemeKey, defaulTheme);
  }
};

export const setHighchartsTheme = () => {
  Highcharts.setOptions(chatmeterTheme);
  return chatmeterTheme;
};

const chatmeterTheme: Highcharts.Options = {
  colors: colors({ nColors: 12 }),
  chart: {
    style: {
      fontFamily: "var(--font-family-base)",
      background: "transparent !important",
    },
    plotBorderColor: "var(--border-color)",
    borderWidth: 0,
    borderRadius: 0,
    plotBackgroundColor: undefined,
    plotShadow: false,
    plotBorderWidth: 0,
  },
  title: {
    style: {
      fontFamily: "var(--font-family-base)",
      color: "var(--body-color)",
      textTransform: "capitalize",
    },
  },
  subtitle: {
    style: {
      fontFamily: "var(--font-family-base)",
      color: "var(--body-color)",
      textTransform: "capitalize",
    },
  },
  xAxis: {
    gridLineColor: "var(--border-color)",
    labels: {
      style: {
        fontFamily: "var(--font-family-base)",
        color: "var(--body-color)",
        fontSize: "12px",
      },
    },
    lineColor: "var(--border-color)",
    tickColor: "var(--border-color)",
    title: {
      style: {
        fontFamily: "var(--font-family-base)",
        color: "var(--body-color)",
      },
    },
  },
  yAxis: {
    gridLineColor: "var(--border-color)",
    labels: {
      style: {
        fontFamily: "var(--font-family-base)",
        color: "var(--body-color)",
      },
    },
    lineColor: "var(--border-color)",
    tickColor: "var(--border-color)",
    tickWidth: 1,
    title: {
      style: {
        fontFamily: "var(--font-family-base)",
        color: "var(--body-color)",
      },
    },
  },
  tooltip: {
    backgroundColor: "var(--card-bg)",
    style: {
      fontFamily: "var(--font-family-base)",
      color: "var(--body-color)",
      backdropFilter: "var(--drop-shadow-4)",
      boxShadow: "var(--drop-shadow-4)",
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        shadow: false,
        color: "var(--body-color)",
        style: {
          fontFamily: "var(--font-family-base)",
          fontSize: "13px",
        },
      },
      marker: {
        lineColor: "transparent !important",
      },
    },
    pie: {
      borderColor: "var(--border-color)",
      borderWidth: 0,
    },
    column: {
      borderColor: "var(--border-color)",
      borderWidth: 0,
    },
  },
  legend: {
    itemStyle: {
      color: "var(--body-color)",
      opacity: 1.0,
    },
    itemHoverStyle: {
      color: "var(--body-color)",
      opacity: 0.75,
    },
    itemHiddenStyle: {
      color: "var(--body-color)",
      opacity: 0.75,
    },
    title: {
      style: {
        fontFamily: "var(--headings-font-family)",
        color: "var(--body-color)",
      },
    },
  },
  credits: {
    style: {
      color: "transparent !important",
      backgroundColor: "transparent !important",
    },
  },
  exporting: {
    // Use ExportAsImage Instead
    enabled: false,
  },
};

export default useHighchartsTheme;
