import React, { ComponentType, Fragment, ReactNode, useState } from "react";
import Waypoint from "react-waypoint";
import { DropdownItem } from "reactstrap";
import { IconToTheSide, Spinner } from "..";
import {
  DropdownButtonSelectorToggle,
  DropdownButtonSelectorToggleProps,
  DropdownPaneOptions,
  DropdownPaneOptionsProps,
  DropdownPaneSelections,
  DropdownSelectorContainerRenderer,
  DropdownSelectorMenuContainer,
  DropdownSelectorMenuContainerProps,
} from "./DropdownSelectorRenderer";
import { DropdownSelectorRendererConfigProps, SearchToSelectRendererState, VarySelectionType } from "./types";

// Props for search selector renderer
export interface SearchToSelectRendererConfigProps<T>
  extends Omit<DropdownSelectorRendererConfigProps<T>, "renderOptions"> {
  /** Message to display while loading */
  loadingMessage?: ReactNode;

  /** Whether to render the header within the search input area */
  renderHeaderWithinSearchToSelect?: boolean;

  renderOptions?: ComponentType<SearchOptionsProps<T>>;

  noMoreOptionsText?: ReactNode;
}

/**
 * Props for the load more component.
 * Inherits all props from SearchToSelectRenderer to support loading state and callbacks.
 */
interface SearchToSelectLoadMoreProps {
  loadingMore: boolean;
  getMoreSearchResults?: () => void;
}

/**
 * Component that handles infinite scrolling functionality.
 * Renders a loading spinner and triggers loading more results when scrolled into view.
 */
function SearchToSelectLoadMore({ loadingMore, getMoreSearchResults }: SearchToSelectLoadMoreProps) {
  if (!getMoreSearchResults) {
    return null;
  }

  return (
    <Waypoint onEnter={getMoreSearchResults} bottomOffset={-20}>
      <div data-testid="load-more-trigger">
        {loadingMore && (
          <DropdownItem disabled toggle={false}>
            <Spinner size="md" timeout={200} center />
          </DropdownItem>
        )}
      </div>
    </Waypoint>
  );
}

/**
 * Options wrapper component for the search to select renderer
 */
const SearchOptionsWrapper = ({
  children,
  ...props
}: { children: ReactNode } & SearchToSelectLoadMoreProps): JSX.Element => {
  const { ...rest } = props;
  return (
    // tabIndex={-1} is required to remove focus due to the overflowY: auto
    <div style={{ overflowY: "auto", paddingBottom: "10px" }} tabIndex={-1}>
      {children}
      <SearchToSelectLoadMore {...rest} />
    </div>
  );
};

/**
 * Options component for the search to select renderer
 */
type SearchOptionsProps<T> = DropdownPaneOptionsProps<T> & {
  loading: boolean;
  activeIndex: number;
};

const SearchOptions = <T extends unknown>(props: SearchOptionsProps<T>): JSX.Element => {
  const { loading, activeIndex, options, getKey, activeKey, ...rest } = props;
  return (
    <Fragment>
      {loading ? (
        <DropdownItem disabled toggle={false}>
          <Spinner size="md" timeout={200} center />
        </DropdownItem>
      ) : (
        <DropdownPaneOptions
          {...rest}
          getKey={getKey}
          activeKey={activeKey ? activeKey : options[activeIndex || 0] && getKey(options[activeIndex || 0])}
          options={options}
        />
      )}
    </Fragment>
  );
};

/**
 * Menu component for the search to select renderer
 */

const SearchMenu = <T extends unknown>(props: DropdownSelectorMenuContainerProps<T>): JSX.Element => {
  const { className, isOpen, children, ...rest } = props;
  return (
    <DropdownSelectorMenuContainer
      {...rest}
      isOpen={isOpen}
      className={`search-to-select-menu ${className || ""}`}
      style={{
        width: "100%",
        maxWidth: "450px",
        ...(isOpen ? { display: "flex", flexDirection: "column" } : {}),
      }}
    >
      {children ?? null}
    </DropdownSelectorMenuContainer>
  );
};

/**
 * Toggle component for the search to select renderer
 */
type SearchToggleProps<T> = Omit<DropdownButtonSelectorToggleProps<T>, "overrideButtonContent"> & {
  loading: boolean;
  loadingMessage: ReactNode;
};

function SearchToggle<T>({ loading, loadingMessage, ...rest }: SearchToggleProps<T>): JSX.Element {
  const toggleProps = rest as DropdownButtonSelectorToggleProps<T>;
  return (
    <DropdownButtonSelectorToggle<T>
      {...toggleProps}
      overrideButtonContent={
        !loading ? undefined : (
          <IconToTheSide
            side="right"
            icon={<Spinner style={{ marginTop: "2px", float: "right" }} timeout={0} size="sm" />}
          >
            {loadingMessage}
          </IconToTheSide>
        )
      }
    />
  );
}

/**
 * Props for the search input component.
 * Inherits all props from SearchToSelectRenderer to support custom rendering and behavior.
 */
interface SearchToSelectInputProps {
  onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  query: string;
  isOpen: boolean;
  onForceSelect?: (value: string) => void;
  Header?: ComponentType<SearchToSelectRendererConfigProps<any>>;
}

/**
 * Input component for the search functionality.
 * Renders a search input with an icon and supports custom header rendering.
 */
export function SearchToSelectInput({ onQueryChange, query, isOpen, onForceSelect }: SearchToSelectInputProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      // Use a small delay to ensure the dropdown is fully rendered
      const timer = setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <div>
      <div
        className="input-group"
        style={{
          padding: "1rem .8rem",
        }}
      >
        <input
          ref={inputRef}
          type="text"
          className="form-control"
          onChange={onQueryChange}
          value={query}
          data-testid="input-text-providers"
          aria-label="input-text-providers"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onForceSelect?.(query);
            }
          }}
        />
        <div className="input-group-append">
          <div className="input-group-text">
            <i className="far fa-search" />
          </div>
        </div>
      </div>
    </div>
  );
}

export type SearchToSelectRendererProps<T> = SearchToSelectRendererConfigProps<T> & SearchToSelectRendererState<T>;

/**
 * A searchable select component that extends the base selector with search functionality.
 * Supports single/multi select, keyboard navigation, and infinite scrolling.
 */
export function SearchToSelectRenderer<T>({
  // Base selector props
  isOpen,
  toggle,
  multi,
  required,
  selectByKey,
  selected,
  onSelected,
  getKey,
  selections,
  options,
  noOptionsText,
  noMoreOptionsText,
  renderOption = (opt: any) => (opt && opt.value ? opt.value : opt),
  renderSelectedOption,
  addSelection,
  removeSelection,
  activeKey,
  disabled,
  placeholder,
  renderMultiSelected = (items: any[]) => items.length + " Selections",
  dataTestId,
  renderOptions: Options = SearchOptions,
  // Additional props
  right,
  style,
  positionFixed = false,
  maxHeight = 450,
  minHeight = 150,
  loadingMore,
  loading,
  query,
  activeIndex,
  getMoreSearchResults,
  onQueryChange,
  everythingSelected,
  loadingMessage,
  renderHeaderWithinSearchToSelect = true,
  onForceSelect,
}: SearchToSelectRendererConfigProps<T> & SearchToSelectRendererState<T>) {
  const [toggleRef, setToggleRef] = useState<any>();

  const varyProps: VarySelectionType<T> = {
    multi: multi,
    required: required,
    selectByKey: selectByKey,
    selected: selected,
    onSelected: onSelected,
  } as VarySelectionType<T>;

  const renderContent = () => (
    <>
      {multi && (
        <DropdownPaneSelections
          renderOption={renderOption}
          renderSelectedOption={renderSelectedOption}
          renderMultiSelected={renderMultiSelected}
          selections={selections}
          getKey={getKey}
          removeSelection={removeSelection}
          activeKey={activeKey}
        />
      )}
      {multi && selections.length > 0 && <DropdownItem divider />}
      <Options
        options={options}
        renderOption={renderOption}
        selections={selections}
        addSelection={addSelection}
        getKey={getKey}
        activeKey={activeKey}
        loading={loading}
        activeIndex={activeIndex}
        multi={multi}
      />
      {everythingSelected ? noMoreOptionsText : !options.length ? noOptionsText : null}
    </>
  );

  return (
    <DropdownSelectorContainerRenderer isOpen={isOpen} toggle={toggle} dataTestId={dataTestId}>
      <SearchToggle<T>
        dropdownRef={setToggleRef}
        disabled={disabled}
        {...varyProps}
        placeholder={placeholder}
        selections={selections}
        renderSelectedOption={renderSelectedOption || renderOption}
        renderMultiSelected={renderMultiSelected}
        removeSelection={removeSelection}
        loading={loading}
        loadingMessage={loadingMessage}
        toggle={toggle}
      />
      <SearchMenu
        right={right}
        positionFixed={positionFixed}
        toggleRef={toggleRef}
        {...varyProps}
        style={style}
        isOpen={isOpen}
        maxHeight={maxHeight}
        minHeight={minHeight}
      >
        {renderHeaderWithinSearchToSelect ? (
          <SearchToSelectInput
            query={query}
            onQueryChange={onQueryChange}
            isOpen={isOpen}
            onForceSelect={onForceSelect}
          />
        ) : null}
        <SearchOptionsWrapper loadingMore={loadingMore} getMoreSearchResults={getMoreSearchResults}>
          {renderContent()}
        </SearchOptionsWrapper>
      </SearchMenu>
    </DropdownSelectorContainerRenderer>
  );
}
