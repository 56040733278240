import React from "react";

export interface FormControlButtonProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

export const FormControlButton = ({ children, className, style, onClick, disabled }: FormControlButtonProps) => {
  return (
    <a
      className={`form-control ${className || ""} form-control-button`}
      onClick={onClick}
      {...(disabled ? { readOnly: true } : {})}
      style={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        ...style,
      }}
    >
      {children}
    </a>
  );
};

const overflowEllipsis = {
  overflow: "hidden",
  textOverflow: "ellipsis",
};
export interface FormControlButtonWithIconProps extends FormControlButtonProps {
  side?: "right" | "left";
  iconClass?: string;
  onIconClick?: () => {};
}

FormControlButton.WithIcon = ({
  side = "right",
  iconClass,
  onIconClick,
  children,
  ...props
}: {
  side?: "right" | "left";
  iconClass?: string;
  onIconClick?: () => {};
  children?: React.ReactNode;
}) => {
  return (
    <FormControlButton {...props}>
      <IconToTheSide
        icon={
          <span
            className={iconClass}
            onClick={(e) => {
              if (onIconClick) {
                e.stopPropagation();
                onIconClick();
              }
            }}
          ></span>
        }
        side={side}
      >
        {children}
      </IconToTheSide>
    </FormControlButton>
  );
};

FormControlButton.IconRight = ({ ...props }) => <FormControlButton.WithIcon side="right" {...props} />;
FormControlButton.IconLeft = ({ ...props }) => <FormControlButton.WithIcon side="left" {...props} />;

export interface IconToTheSideProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon?: React.ReactNode;
  side?: "left" | "right";
}

export const IconToTheSide = ({
  children,
  icon = <i className="fas fa-coffee"></i>,
  side = "left",
  style = {},
  className = "",
  ...props
}: IconToTheSideProps) => {
  return (
    <div
      {...props}
      className={`d-flex w-100 d-flex align-items-center ${className}`}
      style={{ ...overflowEllipsis, ...style }}
    >
      {side === "left" && <span className="content-spacer-right">{icon}</span>}
      {children}
      {side === "right" && (
        <span className="ml-auto d-flex align-items-center">
          <span className="content-spacer-left">{icon}</span>
        </span>
      )}
    </div>
  );
};
