import * as _ from "lodash";
import { CancellationException, stallUnresolvedPromises } from "../../util/async";

/**
 * Represents a search result containing items and pagination information
 */
export interface SearchResult<T> {
  items: T[];
  hasMore: boolean;
  additional?: any;
}

/**
 * Parameters for performing a search operation
 */
export interface SearchParams {
  query: string;
  offset: number;
  additional?: any;
}

/**
 * Configuration options for creating a SearchCache instance
 */
export interface SearchCacheConfig<T> {
  /**
   * Function to fetch search results
   * @param params Search parameters including query, offset, and additional data
   * @returns Promise that resolves to either a SearchResult or an array of items
   */
  getSearchResults: (params: SearchParams) => Promise<SearchResult<T> | T[]>;
  /**
   * Callback function called when search results are received
   * @param result The search result
   * @param loadedMore Whether this result is from loading more items
   */
  onSearchResults?: (result: SearchResult<T>, loadedMore?: boolean) => void;
  /** Debounce delay in milliseconds */
  debounce?: number;
  /** Additional data to pass with search requests */
  additional?: any;
  /** Whether to disable caching of results */
  disableCaching?: boolean;
}

/**
 * The result of creating a SearchCache instance
 * Provides methods to perform and manage searches
 */
export interface SearchCacheResult<T> {
  /**
   * Immediately perform a search
   * @param query The search query
   */
  getImmediate: (query?: string) => Promise<SearchResult<T>>;
  /**
   * Debounced version of getImmediate
   * @param query The search query
   */
  getDebounced: (query?: string) => void;
  /**
   * Load more results for the current query
   */
  getMore: () => Promise<SearchResult<T>>;
  /**
   * Clear the cache
   */
  invalidateCache: () => void;

  /** Clean up resources, e.g. after removing a parent component */
  destroy: () => void;
}

const never = new Promise(() => {});

/**
 * Creates a utility object to keep cache of async search results and handle pagination
 *
 * @param config Configuration object containing:
 *   - getSearchResults: Function to fetch search results
 *   - onSearchResults: Callback for when results are received
 *   - debounce: Debounce delay in milliseconds
 *   - additional: Additional data to pass with requests
 *   - disableCaching: Whether to disable result caching
 * @returns An object with methods to perform and manage searches
 */
export function SearchCache<T>({
  getSearchResults,
  onSearchResults = () => {},
  debounce = 0,
  additional = null,
  disableCaching = false,
}: SearchCacheConfig<T>): SearchCacheResult<T> {
  let cache: { [key: string]: SearchResult<T> } = {};
  let lastQuery = "";
  let fetchingMore = false;
  let isDestroyed = false;
  const callbackIfAlive = (result: SearchResult<T>, loadedMore?: boolean): void => {
    if (!isDestroyed) {
      onSearchResults(result, loadedMore);
    }
  };

  const onlyLatest = stallUnresolvedPromises((query: string) => {
    lastQuery = query;
    fetchingMore = false;
    return query in cache && !disableCaching
      ? Promise.resolve(cache[query])
      : Promise.resolve(getSearchResults({ query: query, offset: 0, additional })).then((result) => {
          if (!result) {
            console.warn("got undefined or null result for search query");
          }
          const wrapped: SearchResult<T> = Array.isArray(result)
            ? { items: result, hasMore: false }
            : (result as SearchResult<T>);
          cache[query] = wrapped;
          return wrapped;
        });
  });

  const getImmediate = (query?: string): Promise<SearchResult<T>> => {
    const promise = onlyLatest(query || "").catch((ex) => {
      if (ex.name === CancellationException) {
        return never;
      } else {
        throw ex;
      }
    }) as Promise<SearchResult<T>>;
    promise.then((results) => callbackIfAlive(results));
    return promise;
  };

  function getMore(): Promise<SearchResult<T>> {
    const myQuery = lastQuery;
    if (!(myQuery in cache)) {
      throw new Error('cannot call getMore before any search results found for "' + myQuery + '"');
    }
    if (fetchingMore) {
      throw new Error("already fetching more");
    }

    const { hasMore: hadMore, items: lastItems, additional: lastAdditional } = cache[myQuery];
    if (!hadMore) {
      return Promise.resolve({ items: lastItems, hasMore: false, additional: lastAdditional });
    } else {
      fetchingMore = true;
      const results = Promise.resolve(
        getSearchResults({ query: myQuery, offset: lastItems.length, additional: lastAdditional })
      ).then((result) => {
        const { items, hasMore, additional } = Array.isArray(result)
          ? { items: result, hasMore: false, additional: lastAdditional }
          : result;
        cache[myQuery] = { hasMore, items: lastItems.concat(items), additional };
        if (myQuery !== lastQuery) {
          throw new Error(
            `query changed from ${myQuery} to ${lastQuery}. Bailing, as more results are no longer relevant`
          );
        }
        return cache[lastQuery];
      });
      results.finally(() => (fetchingMore = false));
      results.then((result) => callbackIfAlive(result, true));
      return results;
    }
  }

  function invalidateCache(): void {
    cache = {};
  }

  return {
    getImmediate,
    getDebounced: _.debounce(getImmediate, debounce),
    getMore,
    invalidateCache,
    destroy: () => {
      isDestroyed = true;
      cache = {};
    },
  };
}
