import React, { ComponentType, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { DropdownItem } from "reactstrap";
import { DropdownSelectorRenderer, DropdownSelectorRendererConfigProps } from "./DropdownSelectorRenderer";
import { type SelectorRendererState, type KeyGetter, type VarySelectionType, type BaseSelectorProps } from "./types";
import * as mobx from "mobx";
export type SelectorProps<
  T,
  RenderProps = DropdownSelectorRendererConfigProps<T>,
  RenderComponent extends ComponentType<RenderProps & SelectorRendererState<T>> = ComponentType<
    RenderProps & SelectorRendererState<T>
  >
> = VarySelectionType<T> &
  BaseSelectorProps<T> &
  RenderProps & {
    Render?: RenderComponent;
  };
/** Bridge adapter that helps with the selection logic so you don't have to deal with keyed or multi types, its just always an array of the object */
export interface Selection<T> {
  onSelected: (items: Readonly<T[]>) => void;
  addSelection: (items: Readonly<T[]>, item: T) => void;
  removeSelection: (items: Readonly<T[]>, item: T) => void;
  clearSelections: () => void;
}

export function Selection<
  T,
  P extends VarySelectionType<T> & { getKey?: KeyGetter<T> } = VarySelectionType<T> & { getKey?: KeyGetter<T> }
>(config: P): Selection<T> {
  const { multi, selectByKey, getKey, onSelected } = config;
  const isRequired = "required" in config ? config.required : false;
  const self = {
    onSelected(items: Readonly<T[]>) {
      if (onSelected) {
        if (multi && selectByKey) {
          onSelected(items.map((x) => resolveKey(x, getKey)));
        } else if (multi && !selectByKey) {
          onSelected(items as T[]);
        } else if (selectByKey && isRequired) {
          onSelected(resolveKey(items[0]!, getKey));
        } else if (selectByKey && !isRequired) {
          onSelected(resolveKey(items[0]!, getKey));
        } else if (!selectByKey && isRequired) {
          onSelected(items[0]!);
        } else if (!selectByKey && !isRequired) {
          onSelected(items[0]);
        } else {
          throw new Error("Invalid selection configuration. This should be unreachable");
        }
      }
    },
    addSelection(items: Readonly<T[]>, newItem: T) {
      self.onSelected(multi ? items.concat(newItem) : [newItem]);
    },
    removeSelection(items: Readonly<T[]>, item: T) {
      if (!multi) {
        self.onSelected([]);
      } else {
        const key = maybeResolveKey(item, getKey);
        if (key) {
          self.onSelected(items.filter((x) => resolveKey(x, getKey) !== key));
        } else {
          self.onSelected(items.filter((x) => x !== item));
        }
      }
    },
    clearSelections() {
      self.onSelected([]);
    },
  };
  return self;
}

function isKeyed<T>(opt: T | { key: string; value: T }): opt is { key: string; value: T } {
  return typeof opt === "object" && opt !== null && "key" in opt && "value" in opt;
}

export function resolveKey<T>(opt: T, keyFn?: KeyGetter<T>): string {
  return keyFn
    ? keyFn(opt)
    : isKeyed(opt)
    ? opt.key || `${opt.value}`
    : // NOTE - there's some legacy support for number keys that's not worth it to put in the types, but is technically supported for JS consumers. They should work fine as a key
    typeof opt === "string" || typeof opt === "number"
    ? (opt as string)
    : `${opt}`;
}

export function maybeResolveKey<T>(opt: T, keyFn?: KeyGetter<T>): string | undefined {
  return keyFn
    ? keyFn(opt)
    : isKeyed(opt)
    ? opt.key
    : typeof opt === "string" || typeof opt === "number"
    ? (opt as string)
    : undefined;
}

function toArray<V>(value: V | V[] | undefined | null): Readonly<V[]> {
  if (!value) {
    return [];
  } else if (isArray(value)) {
    return value as Readonly<V[]>;
  } else {
    return [value];
  }
}

export function isArray<T>(value: T[] | any): value is T[] {
  return Array.isArray(value) || mobx.isObservableArray(value);
}

function rightOuterByKey<V>(
  keyFn: (item: V) => string,
  left: Readonly<V[]>,
  right: Readonly<V[] | undefined | null>
): Readonly<V[]> {
  if (!right || !right.length) {
    return right ?? [];
  } else {
    const leftKeys: { [key: string]: boolean } = {};
    for (const x of left) {
      leftKeys[resolveKey(x, keyFn)] = true;
    }
    return right.filter((x) => !leftKeys[resolveKey(x, keyFn)]);
  }
}

const defaultProps = {
  placeholder: <span className="text-muted">Select...</span>,
  right: false,
  disabled: false,
  multi: false,
  modal: false,
  noOptionsText: (
    <DropdownItem disabled>
      <span className="text-muted">No Results</span>
    </DropdownItem>
  ),
  noMoreOptionsText: (
    <DropdownItem disabled>
      <span className="text-muted">No More Results</span>
    </DropdownItem>
  ),
  renderOption: (opt: any) => (opt && opt.value ? opt.value : opt),
  renderMultiSelected: (items: any[]) => items.length + " Selections",
  dataTestId: "dropdown",
} as const;

export function Selector<
  // type of the items
  T,
  // what the public type of the renderer props are
  RenderProps = DropdownSelectorRendererConfigProps<T>,
  // the type of the renderer component. Must accept the renderer props, as well as the internal state props from the selector
  RenderComponent extends ComponentType<RenderProps & SelectorRendererState<T>> = ComponentType<
    RenderProps & SelectorRendererState<T>
  >
>(props: SelectorProps<T, RenderProps, RenderComponent>) {
  const {
    // Core selection props
    multi,
    selectByKey,
    selected,
    onSelected,
    required,
    disabled,
    maxItems,
    onOpen,
    onClose,
    closeOnClear,
    activeKey,
    getKey,
    options,
    modal,
    // Component prop
    Render = DropdownSelectorRenderer,
    // Get remaining renderer pass-through props
    ...rest
  } = { ...defaultProps, ...props } as SelectorProps<T, RenderProps, RenderComponent>;

  const [isOpen, setIsOpen] = useState(false);

  // this is a bit messy--options can mutate, but we want to retain the selection when the selection is no longer visible in the options.
  // This is somewhat difficult with selectByKey mode, because the option is no longer in any of the props.
  // A common scenario where this happens is when the options are filtered (like in a SearchToSelect). In those cases, we want to still
  // show the selection, and also to still callback that old selection when a new option is selected in multi mode.
  // To achieve this, we have to keep track of the last value of a selected option.
  const selectedItemsCache = useRef<{ [key: string]: T }>({});

  // Update selectedItemsCache when selected or options change
  useEffect(() => {
    if (!selectByKey) return;

    const selectedKeys = toArray(selected as string[] | string | undefined | null);
    const newCache: { [key: string]: T } = {};

    // Keep existing cached items that are still selected
    for (const key of selectedKeys) {
      if (selectedItemsCache.current[key]) {
        newCache[key] = selectedItemsCache.current[key];
      }
    }

    // Add or update selected items from current options
    const optionsKeys = options.reduce((acc, opt) => {
      const key = maybeResolveKey(opt, getKey);
      if (key) {
        acc[key] = opt;
      }
      return acc;
    }, {} as { [key: string]: T });
    for (const key of selectedKeys) {
      const option = optionsKeys[key];
      if (option) {
        newCache[key] = option; // Always update with current version if it exists
      }
    }
    selectedItemsCache.current = newCache;
  }, [selected, selectByKey, options, getKey]);

  // Compute selected array and unselected options
  const selectedArray: readonly T[] = useMemo(() => {
    if (props.selectByKey) {
      const selectedKeys = toArray(selected as string[] | string | undefined | null);
      return selectedKeys
        .map((selection: string) => {
          // First try to find in current options
          const option = options.find((opt) => {
            const key = maybeResolveKey(opt, getKey);
            if (key === undefined) return false;
            return key === selection;
          });
          if (option) return option;
          // If not found in current options, try the cache
          return selectedItemsCache.current[selection];
        })
        .filter((x): x is T => x !== undefined);
    } else {
      return toArray(selected as T[] | T | undefined | null);
    }
  }, [selected, props.selectByKey, options, getKey]);

  const unselectedOptions = useMemo(() => {
    return rightOuterByKey((x) => resolveKey(x, getKey), selectedArray, options);
  }, [selectedArray, options, getKey]);

  const selection = useMemo(() => {
    return Selection<T>(props);
  }, [multi, required, selectByKey, selectedArray, onSelected, getKey]);

  const toggle = useCallback(() => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);

    // This should really go in the renderer
    if (newIsOpen && modal) {
      document.documentElement.style.overflow = "hidden";
    } else if (modal) {
      document.documentElement.style.overflow = "";
    }
    if (newIsOpen) onOpen?.();
    if (!newIsOpen) onClose?.();
  }, [isOpen, modal, onOpen, onClose]);

  const addSelection = useCallback(
    (item: T) => {
      selection.addSelection(selectedArray, item);
    },
    [selection, selectedArray]
  );

  const removeSelection = useCallback(
    (item: T) => {
      selection.removeSelection(selectedArray, item);
      if (closeOnClear) toggle();
    },
    [selection, selectedArray, closeOnClear, toggle]
  );

  const clearSelections = useCallback(() => {
    selection.clearSelections();
  }, [selection]);

  const varied = {
    multi,
    required,
    selectByKey,
    selected,
    onSelected,
  } as VarySelectionType<T>;

  const getKeySafe = useCallback((x: T) => resolveKey(x, getKey), [getKey]);

  const config: SelectorRendererState<T> = useMemo(
    () => ({
      isOpen,
      selections: selectedArray,
      options: unselectedOptions,
      activeKey,
      addSelection,
      removeSelection,
      clearSelections,
      toggle,
      getKey: getKeySafe,
      disabled: disabled ?? false,
      ...varied,
    }),
    [
      isOpen,
      selectedArray,
      unselectedOptions,
      activeKey,
      addSelection,
      removeSelection,
      clearSelections,
      toggle,
      getKeySafe,
      disabled,
      varied,
    ]
  );
  if (props.selectorRef) {
    props.selectorRef.current = config;
  }

  return <Render {...config} {...(rest as RenderProps)} />;
}

// Export static methods that were previously on the class
Selector.defaultProps = defaultProps;
Selector.toArray = toArray;
Selector.rightOuterByKey = rightOuterByKey;
