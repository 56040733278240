import React, { useEffect, useRef, useState } from "react";
import { SearchCache, SearchCacheResult } from "./SearchCache";
import { SearchToSelectRenderer, SearchToSelectRendererConfigProps } from "./SearchToSelectRenderer";
import { isArray, resolveKey, Selection, Selector } from "./Selector";
import {
  type AdditionalSearchToSelectRendererState,
  type SelectorRendererState,
  type BaseSearchToSelectProps,
  type MultiSelectByKeyProps,
  type SearchParams,
  type SearchResult,
  type SelectedItemType,
  type SingleSelectByKeyProps,
  type VarySelectionType,
} from "./types";
import { observer } from "mobx-react";

export type SearchToSelectProps<T> = VarySelectionType<T> &
  BaseSearchToSelectProps<T> &
  SearchToSelectRendererConfigProps<T>;

interface SearchToSelectState<T> {
  results: T[];
  hasRequestYet: boolean;
  resultsHasMore: boolean;
  resultsLoading: boolean;
  resultsLoadingMore: boolean;
  query: string;
  activeIndex: number;
  idCache: { [key: string]: T };
}

const defaultProps = {
  ...Selector.defaultProps,
  placeholder: <span className="text-muted">Search...</span>,
  loadingMessage: <span className="text-muted">Loading...</span>,
  delay: 250,
};

export const SearchToSelect = observer(<T,>(props: SearchToSelectProps<T>) => {
  const {
    getSearchResults: propsGetSearchResults,
    eager,
    loading,
    delay,
    onForceSelect,
    maxItems,
    disableCaching,
    additional,
    ...selectorProps
  } = { ...defaultProps, ...props };
  const { selected, getKey, multi, onSelected, onOpen, onClose, selectByKey } = selectorProps;

  const [state, setState] = useState<SearchToSelectState<T>>({
    results: [],
    hasRequestYet: false,
    resultsHasMore: false,
    resultsLoading: false,
    resultsLoadingMore: false,
    query: "",
    activeIndex: -1,
    idCache: {},
  });

  const selectorRef = useRef<SelectorRendererState<T>>();
  const searcherRef = useRef<SearchCacheResult<T>>();
  const getSearchResultsRef = useRef(propsGetSearchResults);
  getSearchResultsRef.current = propsGetSearchResults;
  const hasInitialized = useRef(false);

  // Initialize searcher
  if (!searcherRef.current) {
    searcherRef.current = SearchCache<T>({
      getSearchResults: getSearchResults,
      onSearchResults,
      debounce: delay,
      additional,
      disableCaching,
    });
  }

  // Helper function to update state partially
  const updateState = (newState: Partial<SearchToSelectState<T>>) => {
    setState((prev) => ({ ...prev, ...newState }));
  };

  function onSearchResults({ items, hasMore }: SearchResult<T>, loadedMore?: boolean): void {
    cacheResultIds(items);
    updateState({
      results: items,
      resultsHasMore: hasMore,
      resultsLoading: false,
      resultsLoadingMore: false,
      activeIndex: loadedMore ? state.activeIndex : -1,
    });
  }

  async function getSearchResults({ query, offset, additional }: SearchParams): Promise<SearchResult<T>> {
    updateState({
      resultsLoading: offset === 0,
      resultsLoadingMore: offset > 0,
      hasRequestYet: true,
    });
    const result = await getSearchResultsRef.current({ query, offset, additional });
    if (isArray(result)) {
      return { items: result, hasMore: false };
    }
    return result;
  }

  function cacheResultIds(items: T[] | null | undefined): void {
    if (items) {
      const newIdCache = !selectByKey
        ? {}
        : items.reduce<{ [key: string]: T }>((sum: { [key: string]: T }, x: T) => {
            const key = resolveKey(x, getKey);
            if (key) sum[key] = x;
            return sum;
          }, {});
      updateState({ idCache: { ...state.idCache, ...newIdCache } });
    }
  }

  const selection = Selection<T, VarySelectionType<T>>(props);

  // First compute selectedKeys since it only depends on props.selected
  const selectedKeys = React.useMemo(() => {
    if (selectByKey) {
      return Selector.toArray(selected) as string[];
    }
    // For non-selectByKey mode, we need to get keys from the selected items directly
    const selectedItems = Selector.toArray(selected) as T[];
    return selectedItems.map((x) => resolveKey(x, getKey));
  }, [selected, selectByKey, getKey]);

  // Then compute options using selectedKeys
  const options: T[] = React.useMemo(() => {
    const results = state.results;
    if (!selectByKey) {
      return results;
    }
    const included = results.reduce<{ [key: string]: T }>((sum, x) => {
      sum[resolveKey(x, getKey)] = x;
      return sum;
    }, {});
    const notIncluded = selectedKeys.filter((x) => !included[x]);
    const plusCached = notIncluded.map((x) => state.idCache[x]).filter((x) => !!x) as T[];
    const combined = results.concat(plusCached);
    return combined;
  }, [state.results, selectByKey, selectedKeys, state.idCache, getKey]);

  // Finally compute selectedArray using options
  const selectedArray = React.useMemo(() => {
    if (selectByKey) {
      const optionsIndex = options.reduce<{ [key: string]: T }>((sum, x) => {
        sum[resolveKey(x, getKey)] = x;
        return sum;
      }, {});
      const selectedIds = Selector.toArray(selected) as string[];
      return selectedIds.map((x) => optionsIndex[x] as T).filter((x): x is T => x !== undefined);
    }
    return Selector.toArray(selected) as T[];
  }, [selected, selectByKey, options, getKey]);

  function resolveSelections(selections: Readonly<string[]>): void {
    if (selectByKey) {
      const unresolved = selections.filter((x) => !state.idCache[x]);
      const typedProps = props as SingleSelectByKeyProps<T> | MultiSelectByKeyProps<T>;
      if (unresolved.length && typedProps.resolveSelection) {
        Promise.resolve(typedProps.resolveSelection(unresolved)).then((xs) => {
          cacheResultIds(xs);
        });
      }
    }
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement> | undefined): void => {
    const query = event?.target?.value || "";
    updateState({ query });
    searcherRef.current?.getDebounced(query);
  };

  const onOpenInner = (): void => {
    if (!state.hasRequestYet) {
      searcherRef.current?.getImmediate(state.query);
    } else if (disableCaching) {
      searcherRef.current?.getImmediate(state.query);
    }
    onOpen?.();
  };

  const onSelectedInner = (items: SelectedItemType<T, typeof props>) => {
    const clearedQuery = undefined;
    const func = onSelected
      ? () => {
          if (!multi) onSearchChange(clearedQuery);
          onSelected?.(items as any);
        }
      : () => {
          if (!multi) onSearchChange(clearedQuery);
        };

    const asArray = isArray(items) ? items : items ? [items] : [];
    if (maxItems && asArray.length >= maxItems) {
      selectorRef.current?.toggle();
    }

    func();
  };

  const onCloseInner = () => {
    const clearedQuery = undefined;
    const func = onClose
      ? () => {
          onSearchChange(clearedQuery);
          onClose?.();
        }
      : () => onSearchChange(clearedQuery);

    func();
  };

  useEffect(() => {
    if (!hasInitialized.current) {
      return;
    }
    if (disableCaching && selectByKey) {
      searcherRef.current?.getImmediate(state.query).then((results) => {
        resolveSelections(selectedKeys);
      });
    }
  }, [props.disableCaching, selectByKey, state.query]);

  useEffect(() => {
    hasInitialized.current = true;
    if (eager) {
      searcherRef.current?.getImmediate();
    }
  }, []);

  useEffect(() => {
    resolveSelections(selectedKeys);
  }, [JSON.stringify(selectedKeys.slice().sort())]);

  useEffect(() => {
    // make react not complain about state updates on unmount
    return () => {
      searcherRef.current?.destroy();
    };
  }, []);

  const stateProps: AdditionalSearchToSelectRendererState<T> = {
    hasRequestYet: state.hasRequestYet,
    query: state.query,
    activeIndex: state.activeIndex,
    loading: state.resultsLoading,
    loadingMore: state.resultsLoadingMore,
    everythingSelected: selectedArray.length === options.length && !state.resultsHasMore && options.length > 0,
    onQueryChange: onSearchChange,
    getMoreSearchResults: state.resultsHasMore && !state.resultsLoadingMore ? searcherRef.current?.getMore : () => {},
    onForceSelect,
  };

  return (
    <Selector<
      T,
      SearchToSelectRendererConfigProps<T> & AdditionalSearchToSelectRendererState<T>,
      typeof SearchToSelectRenderer
    >
      {...selectorProps}
      {...stateProps}
      onOpen={onOpenInner}
      onClose={onCloseInner}
      onSelected={onSelectedInner}
      selectorRef={selectorRef}
      options={options}
      Render={SearchToSelectRenderer}
    />
  );
});
