import { action, computed, observable, reaction, runInAction, toJS, makeObservable } from "mobx";
import * as mobx from "mobx";
import moment from "moment";
import { createViewModel } from "mobx-utils";

import sessionStore from "../../../services/session/SessionStore";
import workflowApi from "../api";
import { UserResolver } from "../../../services/users/UserResolver";
import { CommentsStore } from "./CommentsStore";
import { getStatus, priorities, statuses, workflowEntityType } from "../models";
import { ToastService } from "../../../components/toasts/Toasts";

import * as models from "../models";

export function Task({
  entityId = null,
  entityType = null,
  taskType = "Custom",
  locationId = null,
  taskName = "",
  description = "",
  assignee = undefined,
  provider = undefined,
} = {}) {
  const tt = models.taskTypes.find((x) => x.value === taskType);
  const category = (tt && tt.category) || "Custom";
  return {
    accountId: null,
    taskType,
    category,
    content: {
      locationId,
      taskName,
      assignee,
      status: statuses[0].value,
      priority: priorities[0].value,
      dueDate: undefined,
      description,
      comments: [],
      attachments: [],
      taskData: undefined,
    },
    entityId,
    entityType,
    provider,
  };
}

export class WorkflowTaskStore {
  constructor({
    taskId = null,
    task = null,
    taskTemplate = null,
    taskData = null,
    onTaskAdded = () => {}, // callback.
  }) {
    makeObservable(this, {
      isLoaded: observable,
      original: observable,
      visible: observable,
      editing: observable,
      isSavingTask: observable,
      saveErr: observable,
      isEditMode: observable,
      attemptedSave: observable,
      taskComment: observable,
      taskData: observable,
      setAssignee: action,
      setStatus: action,
      setPriority: action,
      setDueDate: action,
      setLocationId: action,
      setTaskName: action,
      setDescriptionMarkdown: action,
      onTaskLoaded: action.bound,
      loadRelatedEntities: action.bound,
      entityReloaded: action.bound,
      onResponseSubmit: action.bound,
      onResponseUpdate: action.bound,
      onResponseRejected: action.bound,
      onRemoveResponse: action.bound,
      contentEditHistory: computed,
      contentTimeline: computed,
      loadTask: action.bound,
      setEditMode: action,
      saveTask: action.bound,
      isNewTask: computed,
      isValid: computed,
      hide: action.bound,
      revertFormDraft: action.bound,
      hasChanges: computed,
      invalidDescriptions: computed,
      isLoadingTaskData: computed,
      reloadTask: action,
      reloadOnlyTask: action,
      insertComment: action,
      editComment: action,
    });

    if (!(taskId || task || taskTemplate)) {
      throw new Error("must specify one of taskId, task, or taskTemplate in options");
    }
    this.userResolver = new UserResolver();
    this.commentsStore = new CommentsStore(this);
    this.taskData = (task && task.taskData) || taskData;
    this.onTaskAdded = onTaskAdded;

    if (taskTemplate) {
      this.original = taskTemplate;
      this.editing = this.serverTaskOrTemplateToViewModel(this.original);
      this.loadRelatedEntities();
    } else if (task) {
      this.onTaskLoaded(task);
      this.loadRelatedEntities();
    } else if (taskId) {
      mobx.runInAction(() => (this.isLoaded = false));
      this.loadTask(taskId)
        .then((api) => {
          this.onTaskLoaded(api);
          this.loadRelatedEntities();
        })
        .catch((ex) => {
          if (ex.notFound) {
            window.location.href = "/#/workflow2/";
            ToastService.error("Task not found", "Unable to load the specified task; it may have been deleted.");
          }
        });
    }

    this.isEditMode = !!taskTemplate;

    this.unwatchEdits = reaction(
      () => toJS(this.editing),
      (obj) => {
        for (let validator of this.fieldValidators) {
          if (validator.isValid(obj[validator.key])) {
            this.invalidProps.delete(validator.key);
          } else {
            this.invalidProps.set(validator.key, true);
          }
        }
      }
    );
  }

  isLoaded = true;
  original = undefined;
  visible = true;
  editing = undefined;
  isSavingTask = false;
  saveErr = undefined;
  isEditMode = false;
  invalidProps = observable.map();
  attemptedSave = false;
  taskComment = "";

  taskData = undefined;

  setAssignee = (assignee) => (this.editing.assignee = assignee);
  setStatus = (status) => (this.editing.status = status);
  setPriority = (priority) => (this.editing.priority = priority);
  setDueDate = (dueDate) => (this.editing.dueDate = dueDate);
  setLocationId = (locationId) => (this.editing.locationId = locationId);
  setTaskName = (taskName) => (this.editing.taskName = taskName);
  setDescriptionMarkdown = (description) => (this.editing.description = description);

  fieldValidators = [
    {
      key: "taskName",
      message: "Task Name is required",
      isValid: (value) => !!(value && value.trim()),
    },
  ];

  serverTaskOrTemplateToViewModel(serverTask) {
    let content = serverTask.content;
    return createViewModel(
      observable({
        _id: serverTask._id,
        alternateTaskTypes: serverTask.alternateTaskTypes || [serverTask.taskType],
        friendlyId: serverTask.friendlyId,
        prefixedFriendlyId: serverTask.prefixedFriendlyId,
        author:
          serverTask.author && serverTask.author.type === "User"
            ? this.userResolver.fetchOne(serverTask.author.id)
            : "Task Generator",
        createdDate: moment(serverTask.createdDate),
        taskType: serverTask.taskType,
        category: serverTask.category,
        locationId: content.locationId,
        assignee: content.assignee && { username: content.assignee.username, id: content.assignee.userId },
        status: content.status,
        priority: content.priority,
        dueDate: content.dueDate && moment(content.dueDate).toDate(),
        taskName: content.taskName,
        description: content.description,
        attachments: content.attachments,
        entityType: serverTask.entityType,
        entityId: serverTask.entityId,
        provider: serverTask.provider,
      })
    );
  }

  viewModelToServerUpdate(editModel) {
    return {
      _id: editModel._id,
      friendlyId: editModel.friendlyId,
      taskType: editModel.taskType,
      content: {
        locationId: editModel.locationId,
        status: editModel.status,
        priority: editModel.priority,
        taskName: editModel.taskName,
        description: this.isNewTask ? this.taskComment : editModel.description,
        // attachments: editModel.attachments,
        dueDate: editModel.dueDate && moment(editModel.dueDate).valueOf(),
        assignee: editModel.assignee && editModel.assignee.id,
      },
      entityType: editModel.entityType,
      entityId: editModel.entityId,
      provider: editModel.provider,
    };
  }

  onTaskLoaded(apiTask) {
    this.editing = this.serverTaskOrTemplateToViewModel(toJS(apiTask));
    this.original = apiTask;
    this.isLoaded = true;
  }

  loadRelatedEntities(force = false) {
    this.commentsStore.initializeComments(this.original.content.comments);

    // fetch entity
    if (!!(this.original.entityId && !this.taskData && this.original._id) || force) {
      return workflowApi.fetchEntity(this.original._id).then((data) =>
        mobx.runInAction(() => {
          this.taskData = data || { empty: true };
        })
      );
    }

    // fetch location
  }
  entityReloaded(entity) {
    this.taskData = entity;
  }

  onResponseSubmit(response) {
    this.taskData.review.responses.push(response);
  }

  onResponseUpdate(response) {
    const index = this.taskData.review.responses.findIndex((r) => r._id.$oid === response._id.$oid);
    if (index >= 0) {
      this.taskData.review.responses[index] = response;
    }
  }

  onResponseRejected(response) {
    this.taskData.review.responses = this.taskData.review.responses.filter((rr) => rr._id.$oid !== response._id.$oid);
  }

  onRemoveResponse(response) {
    this.taskData.review.responses = this.taskData.review.responses.filter((r) => r._id.$oid !== response._id.$oid);
  }

  get contentEditHistory() {
    const serverTask = this.original;
    if (!(serverTask && serverTask._id)) {
      // I'm a draft. No history
      return [];
    } else {
      return [
        // prepend created details to the history in the appropriate format
        {
          timestamp: serverTask.createdDate,
          author: serverTask.author,
          changeDescriptions: ["Task Created"],
          revert: [],
        },
        ...serverTask.contentEditHistory,
      ];
    }
  }

  get contentTimeline() {
    //TODO - take contentEditHistory, scrub duplicate comments and grab from comments thread
  }

  loadTask(taskId) {
    return workflowApi.getTask(taskId);
  }

  setEditMode(bool) {
    this.attemptedSave = false;
    this.isEditMode = bool;
  }

  saveTask({ force = false } = {}) {
    this.attemptedSave = true;
    if (force || this.isValid) {
      this.isSavingTask = true;
      this.saveErr = undefined;
      const taskToSave = this.viewModelToServerUpdate(toJS(this.editing));
      const taskComment = this.taskComment;
      return workflowApi
        .saveTask(taskToSave)
        .then((taskSaveRequest) => {
          if (taskComment && !!this.original._id) {
            return this.insertComment(taskComment);
          } else {
            return taskSaveRequest;
          }
        })

        .then(
          action("saveActionSuccess", (resp) => {
            this.attemptedSave = false;
            this.isSavingTask = false;
            this.isEditMode = false;
            const wasCreate = !this.original._id;
            Object.assign(this.original, resp.task);
            if (wasCreate) {
              this.onTaskAdded(this.original);
            }
            // TODO - update comments?
            this.editing = this.serverTaskOrTemplateToViewModel(this.original);
            return this.original;
          })
        )
        .catch(
          action("saveActionFail", (ex) => {
            this.isSavingTask = false;
            this.saveErr = ex;
            throw ex;
          })
        );
    } else {
      return Promise.reject("Task is invalid");
    }
  }

  get isNewTask() {
    return !(this.original && this.original._id);
  }

  get isValid() {
    return !this.invalidProps.size;
  }

  // aggressively destroy.
  // Not really possible to redisplay a hidden store in the view
  hide() {
    this.visible = false;
    this.unwatchEdits && this.unwatchEdits();
  }

  revertFormDraft() {
    this.editing.reset();
  }

  get hasChanges() {
    return this.editing.isDirty;
  }

  get invalidDescriptions() {
    return this.fieldValidators.filter((x) => this.shouldShowInvalid(x.key)).map((x) => x.message);
  }

  shouldShowInvalid(prop) {
    return this.invalidProps.has(prop) && (this.attemptedSave || this.editing.isPropertyDirty(prop));
  }

  get isLoadingTaskData() {
    return this.original.entityId && !this.taskData && !!this.original._id;
  }

  reloadTask = () => {
    return this.loadTask(this.original._id)
      .then((api) => {
        this.onTaskLoaded(api);
        this.loadRelatedEntities(true);
      })
      .catch((ex) => {
        if (ex.notFound) {
          window.location.href = "/#/workflow2/";
          ToastService.error("Task not found", "Unable to load the specified task; it may have been deleted.");
        }
      });
  };

  reloadOnlyTask = () => {
    return this.loadTask(this.original._id).then(this.onTaskLoaded);
  };

  insertComment(body, taskResponse) {
    let userId = sessionStore.user.userId;
    const comment = {
      commentId: "",
      created: undefined,
      lastUpdated: undefined,
      authorUserId: userId,
      body,
      author: this.userResolver.fetchOne(userId),
    };
    return workflowApi.addComment(this.original._id, comment).then((commentResponse) => {
      return this.loadTask(this.original._id).then((api) => {
        runInAction(() => (this.isEditMode = false));
        this.onTaskLoaded(api);
        return api;
      });
    });
  }

  editComment(body) {
    this.taskComment = body;
  }
}
