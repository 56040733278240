/**

 set misc things that need to be global, or at the very least are temporary hacks
*/

window.$ = window.jQuery = require("jquery");
/**
 * Removing Bootstrap-Sass (which uses Bootstrap 3) broke several dropdowns, including:
 * - locationsManager.html -> download button dropdown
 * - usersManagers.html -> download button dropdown
 * - emailAlerts/manage/manage.html -> Table actions dropdown
 * - reportManager.html -> Add new report config dropdown
 * - listingOptimization.html -> download button
 * - listingAccuracy.html -> download button
 * - duplicateListings.html -> download button
 * - missingListings.html -> download button
 *
 * The below require('bootstrap') is to return funcitonality to these dropdowns.
 */
require("bootstrap");
$.fn.datetimepicker = require("eonasdan-bootstrap-datetimepicker"); // TODO - I think this module is pulling in its own copy of jquery 3

require("select2/index"); // adds to jquery for some angular directives (or at least the select2 one)
window.angular = require("angular");

window.JSON = require("json3");

// TODO remove me. Should just use built-ins or lodash
window._ = require("underscore");

/**
 * Leaflet
 */
window.L = require("leaflet");

require("leaflet.markercluster");
require("leaflet.sentimentheat");

window.Highcharts = require("highcharts");

require("whatwg-fetch"); // fetch API polyfill https://github.com/github/fetch

// window.require = require
window.ace = require("brace");
require("brace/mode/scss");
require("brace/mode/handlebars");
require("brace/theme/chrome");
// require('ace-builds/src-min-noconflict/theme-twilight')
